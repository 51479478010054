import {Component, OnInit} from '@angular/core';
import {Mindmap, MindmapEvent} from 'mindmap-library';
import {ActivatedRoute} from '@angular/router';
import {debounceTime, distinctUntilChanged, filter, flatMap, take, tap} from 'rxjs/operators';
import {combineLatest, Observable, Subject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';

@Component({
    selector: 'app-mindmap',
    templateUrl: './mindmap.component.html',
    styleUrls: ['./mindmap.component.scss']
})
export class MindmapComponent implements OnInit {

    public eventClickSubject = new Subject<string>();
    public mindmap: Mindmap;
    public mindmapEntityWrapper: DataEntity;
    public mindmapPicture: string;

    private debounceEvents = new Subject<MindmapEvent>();

    constructor(private activatedRoute: ActivatedRoute,
                private octopusConnect: OctopusConnectService) {
    }

    ngOnInit() {
        combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams).pipe(
            filter(([params, query]) => !!query.token && !!params.idMindmap),
            flatMap(([params, query]) => this.initAuthentication(query.token).pipe(
                flatMap(() => this.initMindmap(params.idMindmap))
            )),
        ).subscribe();

        // The onMindmapChange has triggered by any mindmap changes : Write "this"
        //  trigger the onMindmapChange 4 times. We just wait 400ms after a change
        //  to avoid to spam the server. Because at any change, all the mindmap is
        //  saved, there are no risk to loose data.
        this.debounceEvents.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            flatMap((event: MindmapEvent) => this.saveMindmap(event.mindmap))
        ).subscribe();
    }

    public menuEvent(evt: string): void {
        this.eventClickSubject.next(evt);
    }

    public onMindmapChange(event: MindmapEvent) {
        this.debounceEvents.next(event);
    }

    /**
     * get img in blob format
     * open modal to ask at who sending the picture
     * @param img of mindmap in blob format
     */
    public sendMindmap(img: string): void {
        this.mindmapPicture = img;
    }

    /**
     * picture was send or not by email in all case we close modal
     */
    closeMailModal(): void {
        this.mindmapPicture = null;
    }

    /**
     * Load the mindmap
     * HACK : if the mindmap does not exist but the DataEntity exist, set a default map
     * @param idMindmap
     * @private
     */
    private initMindmap(idMindmap: string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('mindmaps', idMindmap).pipe(
            take(1),
            tap(entity => this.mindmapEntityWrapper = entity),
            tap(entity => this.mindmap = entity.get('data')),
            tap((entity) => {
                if (this.mindmap === null) {
                    this.mindmap = {
                        id: entity.id.toString(),
                        cards: []
                    };
                }
            })
        );
    }

    /**
     * This is an hack to be logged by a given token
     * @param token
     * @private
     */
    private initAuthentication(token: string): Observable<DataEntity> {
        const myDate = new Date();
        let timestampDate;
        myDate.setHours(myDate.getHours() + 24);
        timestampDate = Math.floor(new Date(myDate).getTime());

        localStorage.setItem('http_accessToken', JSON.stringify(token));
        localStorage.setItem('http_expires_in', JSON.stringify(timestampDate));
        localStorage.setItem('http_currentUser', JSON.stringify({}));

        return this.octopusConnect.authenticated('http');
    }

    /**
     * Save the current mindmap
     */
    private saveMindmap(mindmap: Mindmap): Observable<DataEntity> {
        this.mindmapEntityWrapper.set('data', JSON.stringify(mindmap));
        return this.mindmapEntityWrapper.save();
    }
}
