import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-mail-modal',
    templateUrl: './mail-modal.component.html',
    styleUrls: ['./mail-modal.component.scss']
})
export class MailModalComponent implements OnInit {
    @Input() mindmapPicture: string;
    public mailData: { email: string, description: string, picture: string } = {email: '', description: '', picture: ''};
    public emailForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
        description: [''],
    });
    public isOpen = false;
    @Output() close = new EventEmitter<boolean>();

    constructor(private formBuilder: FormBuilder,
                private octopusConnectService: OctopusConnectService) {
    }

    ngOnInit() {
        setTimeout(() => {
            this.isOpen = true;
        });
    }

    onSubmit() {
        this.mailData = this.emailForm.value;
        this.mailData.picture = this.mindmapPicture;
        // send to back here to see with jm how
        this.sendMindMapToBack().subscribe(mail => {
            console.log(mail + ' mail send');
        });
        this.close.next(true);
    }

    /**
     * ask back to send the current mindmap by mail
     */
    private sendMindMapToBack(): Observable<DataEntity> {
        return this.octopusConnectService.createEntity('mail-mindmap', this.mailData);
    }

    cancel(): void {
        this.isOpen = false;
        setTimeout(() => {
            this.close.next(true);
        }, 800);
    }

}
