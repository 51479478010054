import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MindmapComponent} from './mindmap/mindmap.component';
import {MindmapLibraryModule} from 'mindmap-library';

import {OctopusConnectModule} from 'octopus-connect';
import {environment} from '../environments/environment';
import {MailModalComponent} from './mail-modal/mail-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

export const defaultApiURL = environment.baseApiUrl;

@NgModule({
    declarations: [
        AppComponent,
        MindmapComponent,
        MailModalComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MindmapLibraryModule,
        CKEditorModule,
        OctopusConnectModule.forRoot({
            defaultInterface: 'http',
            maxRetry: 5,
            retryTimeout: 50000,
            configuration: {
                http: {
                    apiUrl: defaultApiURL,
                    useApiExtension: true,
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            },
            map: {
                'users': 'http',
                'mindmaps': 'http',
                'mail-mindmap': 'http',
            }
        }),
        ReactiveFormsModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
